export const archivedFontReplacementsData = {
  "Aften Screen": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1033,
              "replace_with": "Social Variable"
          },
          {
              "weight": "400",
              "style": "italic",
              "vid": 1033,
              "replace_with": "Social Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1036,
              "replace_with": "Social Variable"
          }
      ]
  },
  "Benton Sans": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1034,
              "replace_with": "Social Variable"
          },
          {
              "weight": "400",
              "style": "italic",
              "vid": 1034,
              "replace_with": "Social Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1036,
              "replace_with": "Social Variable"
          },
          {
              "weight": "700",
              "style": "italic",
              "vid": 1036,
              "replace_with": "Social Variable"
          },
          {
              "weight": "900",
              "style": "normal",
              "vid": 1037,
              "replace_with": "Social Variable"
          },
          {
              "weight": "900",
              "style": "italic",
              "vid": 1037,
              "replace_with": "Social Variable"
          }
      ]
  },
  "Benton Sans Condensed": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1043,
              "replace_with": "Social Condensed Variable"
          },
          {
              "weight": "400",
              "style": "italic",
              "vid": 1043,
              "replace_with": "Social Condensed Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1045,
              "replace_with": "Social Condensed Variable"
          },
          {
              "weight": "700",
              "style": "italic",
              "vid": 1045,
              "replace_with": "Social Condensed Variable"
          }
      ]
  },
  "Benton Sans Extra Compressed": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1145,
              "replace_with": "Diatype Condensed Variable"
          },
          {
              "weight": "400",
              "style": "italic",
              "vid": 1145,
              "replace_with": "Diatype Condensed Variable"
          },
          {
              "weight": "500",
              "style": "normal",
              "vid": 1146,
              "replace_with": "Diatype Condensed Variable"
          },
          {
              "weight": "500",
              "style": "italic",
              "vid": 1146,
              "replace_with": "Diatype Condensed Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1147,
              "replace_with": "Diatype Condensed Variable"
          },
          {
              "weight": "700",
              "style": "italic",
              "vid": 1147,
              "replace_with": "Diatype Condensed Variable"
          },
          {
              "weight": "900",
              "style": "normal",
              "vid": 856,
              "replace_with": "Gravity Variable"
          },
          {
              "weight": "900",
              "style": "italic",
              "vid": 856,
              "replace_with": "Gravity Variable"
          }
      ]
  },
  "Bureau Grot": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1034,
              "replace_with": "Social Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1036,
              "replace_with": "Social Variable"
          }
      ]
  },
  "Bureau Grot Condensed": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1043,
              "replace_with": "Social Condensed Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1045,
              "replace_with": "Social Condensed Variable"
          }
      ]
  },
  "Bureau Grot Ultra": {
    "font-style": "normal",
    "variantMap": [
        {
            "weight": "400",
            "style": "normal",
            "vid": 218,
            "replace_with": "Stanley",
        }
    ]
},
  "Bureau Grot Ultra Black": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "900",
              "style": "normal",
              "vid": 218,
              "replace_with": "Stanley"
          }
      ]
  },
  "Fort": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1023,
              "replace_with": "ROM Variable"
          },
          {
              "weight": "400",
              "style": "italic",
              "vid": 1023,
              "replace_with": "ROM Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1026,
              "replace_with": "ROM Variable"
          },
          {
              "weight": "700",
              "style": "italic",
              "vid": 1026,
              "replace_with": "ROM Variable"
          }
      ]
  },
  "Fort Extrabold": {
        "font-style": "normal",
        "variantMap": [
            {
                "weight": "400",
                "style": "normal",
                "vid": 1028,
                "replace_with": "ROM Variable"
            },
            {
                "weight": "400",
                "style": "italic",
                "vid": 1028,
                "replace_with": "ROM Variable"
            },
        ]
    },
    "Guyot Headline": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 513,
              "replace_with": "Guyot Text"
          },
          {
              "weight": "400",
              "style": "italic",
              "vid": 514,
              "replace_with": "Guyot Text"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 515,
              "replace_with": "Guyot Text"
          },
          {
              "weight": "700",
              "style": "italic",
              "vid": 516,
              "replace_with": "Guyot Text"
          }
      ]
  },
  "Planet": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1169,
              "replace_with": "MVB Solitaire"
          },
          {
              "weight": "400",
              "style": "italic",
              "vid": 1170,
              "replace_with": "MVB Solitaire"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 1171,
              "replace_with": "MVB Solitaire"
          },
          {
              "weight": "700",
              "style": "italic",
              "vid": 1172,
              "replace_with": "MVB Solitaire"
          }
      ]
  },
  "Titling Gothic FB Condensed": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 1043,
              "replace_with": "Social Condensed Variable"
          },
          {
              "weight": "500",
              "style": "normal",
              "vid": 1044,
              "replace_with": "Social Condensed Variable"
          }
      ]
  },
  "Titling Gothic FB Narrow": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 881,
              "replace_with": "Monument Grotesk Variable"
          },
          {
              "weight": "500",
              "style": "normal",
              "vid": 882,
              "replace_with": "Monument Grotesk Variable"
          }
      ]
  },
  "Titling Gothic FB Normal": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "200",
              "style": "normal",
              "vid": 880,
              "replace_with": "Monument Grotesk Variable"
          },
          {
              "weight": "400",
              "style": "normal",
              "vid": 881,
              "replace_with": "Monument Grotesk Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 883,
              "replace_with": "Monument Grotesk Variable"
          }
      ]
  },
  "Titling Gothic FB Wide": {
      "font-style": "normal",
      "variantMap": [
          {
              "weight": "400",
              "style": "normal",
              "vid": 881,
              "replace_with": "Monument Grotesk Variable"
          },
          {
              "weight": "700",
              "style": "normal",
              "vid": 883,
              "replace_with": "Monument Grotesk Variable"
          }
      ]
  }
};