import _ from 'lodash';
import tinycolor from "tinycolor2";
import { FRONTEND_DATA, YTransactionTypes } from '../../globals';
import opentype from 'opentype.js'
import { parse as parseFontFaceSrc } from 'css-font-face-src';
import { wsProvider } from "../../lib/multi-user";
import { removeDiacritics } from '@cargo/common/font-helpers';

import selectors from "../../selectors";

const fontLoadResults = {};

export const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

// Requires value string, e.g. "12rem"
// Outputs -> ["12", "rem"]
export function processCSSValue (rule) {

	// If we don't have  a rule, return an empty string.
	if( !rule || rule == "" ){ return "" }
	// Some regex to split things up...
	// Outputs -> ["12rem", "12", "rem", index: 0, input: "12rem", groups: undefined]
	const parseCSSRule = /^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/
	// Run the regex
	let parsedRule = rule.toString().match( parseCSSRule );
	// If the Regex returns null, return array ["0px", 0, "px"]
	if( parsedRule == null ){
		parsedRule = [0+rule, 0, rule]
	}
	// Just grab what we need.
	parsedRule = [Number(parsedRule[1]), parsedRule[2]]
	// Return the result.
	return parsedRule
}

let lastRandomColorType = null;

export const getRandomColor = ( initialColor ) => {

	let originalColor = tinycolor(initialColor);
	let origHsl = originalColor.toHsl();
	origHsl.a = originalColor.getAlpha();

	let allChoices = ['tetrad', 'complement', 'splitcomplement', 'analogous'];
	let availableChoices = [];

	for (let i =0; i < allChoices.length; i++){
	    if ( allChoices[i] !== lastRandomColorType){
	        availableChoices.push(allChoices[i]);
	    }
	}

	let newColor = tinycolor.fromRatio({
	    h: Math.random()*360,
	    s: Math.random()*1,
	    l: Math.random()*1
	});

	let curHsl = newColor.toHsl();
	let curAlpha = newColor.getAlpha();


	let darken = ( Math.random() > 0.5 || curHsl.l > .85 ) && curHsl.l >.15 ;

	if ( darken ){
	    curHsl.l = (curHsl.l)*Math.random();
	} else {
	    curHsl.l = 1-(1-curHsl.l)*Math.random();
	}

	let saturate = ( Math.random() > 0.5 || curHsl.s < .15 ) && curHsl.s < .85;

	if ( saturate ){
	    curHsl.s = 1-(1-curHsl.s)*Math.random()
	} else {
	    curHsl.s = curHsl.s*Math.random();
	}

	newColor = tinycolor.fromRatio(curHsl);

	let choiceOne = availableChoices[Math.floor(Math.random()*availableChoices.length)];

	if ( choiceOne == 'tetrad' ){
	    
	    let choiceTwo = Math.floor(Math.random()*3+1);
	    newColor = newColor.tetrad()[choiceTwo];

	} else if ( choiceOne == 'splitcomplement'){
	    
	    let choiceTwo = Math.floor(Math.random()*2+1);
	    newColor = newColor.splitcomplement()[choiceTwo];

	} else if (choiceOne =='complement') {
	    newColor = newColor.complement()              
	} else {              
	    newColor = newColor.analogous()[1];
	}

	let readable = tinycolor.readability(originalColor, newColor);
	let significantContrast = readable.brightness > 45 || readable.color > 350;

	let newAlpha = origHsl.a;
	
	// if( newAlpha == 0 ){
		newAlpha = 1
	// }
	
	newColor.setAlpha(newAlpha);

	lastRandomColorType = choiceOne;

	return newColor
}


export const getColorString = ( colorObj, format ) => {

    let colorString = null;

    switch( format ){
    case "name":
        colorString = colorObj.toName();
        break;
    case "string":
    	colorString = colorObj.toHexString();
    	break
    case "rgb":
        colorString = colorObj.toRgbString();
        break;
    case "rgba":
        colorString = colorObj.toRgbString();
        break;
    case "hex":
        let rgb = colorObj.toRgb()
        // force saving in rgb if alpha is less than 1
        colorString = rgb.a < 1 ? colorObj.toRgbString() : colorObj.toHexString();
        break;
    case "hsv":
        // colorObj.setAlpha(0.5);
        colorString = colorObj.toHsvString();
        break;
    case "hsl":
        // colorObj.setAlpha(0.5);
        colorString = colorObj.toHslString();
        break;
    }

    return colorString;
}

export const setColorPickerPosition = ( eventX, eventY, picker, type ) => { 
	// Positions picker in view, not related to selecting colors
	let pickerWidth  = picker.current.offsetWidth, //picker.offsetWidth,
	    pickerHeight = type == 'tabbed' ? 342 : 309, //picker.offsetHeight ( offset height does not calc correctly for picker tabs )
	    viewWidth    = window.document.documentElement.clientWidth,
	    viewHeight   = window.document.documentElement.clientHeight,
	    buffer       = 20,
	    x            = 0,
	    y            = 0,
	    obj          = {x : null, y: null };

    if( ( viewWidth - eventX ) >= pickerWidth - buffer ){
        // Open right
        x = eventX;
    } else {
        // Open left
        x = eventX - pickerWidth;
    }

    if( (viewHeight - eventY) >= pickerHeight + buffer ){
        // Open below
        y = eventY;
    } else {
        // Open Above
        y = eventY - pickerHeight;
    }

    obj = { x: x, y: y}
    return obj;
}

export const calcWindowPercentage = ( percentage, orientation ) => {
	// Calc VH or VW
	let windowHeight = window.innerHeight;
	let windowWWidth = window.innerWidth;

	if( orientation == 'width' ){
			let width = (windowWidth/100)*percentage
			return width
	}

	if( orientation == 'height' ){
		let height = (windowHeight/100)*percentage
		return height
	}

}

/**
 * Get supported file types as an array, optionally filtered by MIME type base
 *
 * @param {string} base - A MIME type base, as a string
 * @return {Array} An array of supported file types
 *
 * @example
 *
 *     getSupportedFileTypes('audio')
 */
export const getSupportedFileTypes = (base = null) => {
	const supported = [
		"image/png",
		"image/jpeg",
		"image/jpg",
		"image/gif",
		"image/svg",
		"image/svg+xml",

		"audio/mpeg",
		"audio/ogg",
		"audio/flac",
		"audio/aac",
		"audio/wav",


		"video/mp4",
		"video/webm",
		"video/quicktime",

		"font/woff",
		"application/font-woff",
		"font/woff2"
	];
	return supported.reduce((prevVal, currVal, currIndex) => {
		if ( base === null || currVal.startsWith(`${base}/`) ) {
			return prevVal.concat([currVal]);
		}
		return prevVal;
	}, [])
}

export const formatDate = (timestamp) => {
	// Returns formatting as such: Oct 24 2021, 4:07pm
	let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	if( !timestamp ){ return }
	let fDate		= new Date(timestamp),
		day			= fDate.getDate(),
		monthInt 	= parseInt(fDate.getMonth()),
		month		= monthNames[monthInt],
		year		= fDate.getFullYear(),
		hours		= fDate.getHours() % 12 === 0 ? 12 : fDate.getHours() % 12,
		minutes		= fDate.getMinutes().toString().match(/\d/g).length === 1 ? '0'+fDate.getMinutes() : fDate.getMinutes(),
		seconds		= '0' + fDate.getSeconds(),
		meridiem	= fDate.getHours() >= 12 ? 'pm' : 'am';

	let unixStartToday     = new Date().setHours(0, 0, 0, 0);
	let unixStartYesterday = unixStartToday - 86400000;
	let timeOfLastUpdate = null;
	// If our publish time is greater than 12:00AM today. 
	// we published today.
	if( timestamp >= unixStartToday ){
		timeOfLastUpdate = 'today'
	}
	// If our publish time is less than 12:00AM today, and greater than 12:00AM yesterday
	// then we published yesterday.
	if( timestamp <= unixStartToday && timestamp >= unixStartYesterday ){
		timeOfLastUpdate = 'yesterday'
	}

	if ( timeOfLastUpdate !== null ){
		return timeOfLastUpdate + ', ' + hours + ':' + minutes + meridiem;
	} else {
		return month + ' ' + day + ' ' + year + ', ' + hours + ':' + minutes + meridiem;
	}
	
}

export const addNonMobileInheritanceToPropWatcherConfig = propWatcherConfig => {

	if(!propWatcherConfig.selector.includes('.mobile')) {
		return;
	}

	// loop over all the properties of the mobile selector
	// and add inheritance to their non-mobile counterparts
	if(!propWatcherConfig.hasOwnProperty('inheritsFrom')) {
		// ensure we have an inheritsFrom field
		propWatcherConfig.inheritsFrom = [];
	} else if(!_.isArray(propWatcherConfig.inheritsFrom)) {
		// and that that field is an array
		propWatcherConfig.inheritsFrom = [propWatcherConfig.inheritsFrom];
	}

	// insert an entry into the inheritance array
	// containing a config to the non-mobile counterpart
	// of this mobile property
	propWatcherConfig.inheritsFrom.push({
		parser: propWatcherConfig.parser,
		selector: propWatcherConfig.selector.replace(/\s*\.mobile\s*(\s*\[id\]\s*)?/gi, ''),
		property: propWatcherConfig.property
	});

}

export const GetActiveLongHandValues = (selector, property, sides) => {
	
	// check to see if any of the padding longhands are in use. If so, invalidate
	// the value label on the main padding slider and set it to "mixed"
	let activeLongHandValues = _.uniq(sides.filter(
		longhand => {

			let longhandValue = selector[property + longhand];
			
			if(longhandValue === null || longhandValue === selector) {
				// if not set, or set to the same value as 'padding', the longhand value is not active
				return false;
			}

			return true

		}
	));

	if( selector[property+sides[0]] === selector[property+sides[1]] &&
		selector[property+sides[0]] === selector[property+sides[2]] && 
		selector[property+sides[0]] === selector[property+sides[3]] 
	){
		// If all 4 values are identical, we have no active longhand values.
		// This covers cases when we're changing the range extension's primary value by typing in the input. 
		activeLongHandValues = [];
	}

	return activeLongHandValues;
}

// Helper function for multi sided scrubbers with normal property construction (i.e. padding / padding-top )
export const handleBasicMultiScrubber = ( baseProperty, selector, changedProp, changedProperties, getExistingCSSProperties ) => {

	let basePropertyWithDash = baseProperty+'-'
	let existingPaddingAttrs    = getExistingCSSProperties(basePropertyWithDash, selector);
	let existingMainPaddingAttr = getExistingCSSProperties(baseProperty, selector);

	let currentValues = { ...existingMainPaddingAttr, ...existingPaddingAttrs };
	changedProperties = { ...currentValues, ...changedProperties }

	let isNotShorthandScrubber = changedProp?.match( baseProperty ) && changedProp?.match(/-/g)?.length == 1;

	let nulledSides = {
		[`${basePropertyWithDash}top`]    : null,
		[`${basePropertyWithDash}right`]  : null,
		[`${basePropertyWithDash}bottom`] : null,
		[`${basePropertyWithDash}left`]   : null
	};

	// Object sync order depends on which scrubber value is being manipulated
	// If we are changing a sub slider, prioritize the changed properties.
	// If we are changing the main slider, prioritize the sub properties.
	changedProperties = isNotShorthandScrubber ? { ...nulledSides, ...changedProperties } : { ...changedProperties, ...nulledSides };

	if(    isNotShorthandScrubber
		&& changedProperties[`${basePropertyWithDash}top`] === changedProperties[`${basePropertyWithDash}right`]
		&& changedProperties[`${basePropertyWithDash}top`] === changedProperties[`${basePropertyWithDash}bottom`]
		&& changedProperties[`${basePropertyWithDash}top`] === changedProperties[`${basePropertyWithDash}left`]
		&& ( 
			   changedProperties[`${basePropertyWithDash}top`]    !== changedProperties[baseProperty]
			|| changedProperties[`${basePropertyWithDash}right`]  !== changedProperties[baseProperty]
			|| changedProperties[`${basePropertyWithDash}bottom`] !== changedProperties[baseProperty]
			|| changedProperties[`${basePropertyWithDash}left`]   !== changedProperties[baseProperty]
		  )
	){
		changedProperties[baseProperty] = changedProperties[`${basePropertyWithDash}top`];
	}

	if(isNotShorthandScrubber) {

		const shorthandProperties = _.pick(changedProperties, _.keys(nulledSides));
		const shortHandPropertiesValues = _.values(shorthandProperties);

		// if all shorthands are the exact same value, just set longhand and delete all shorthands
		if(shortHandPropertiesValues.length === 4 && shortHandPropertiesValues.every((val) => val === shortHandPropertiesValues[0])) {

			// when setting the exact same shorthands, kill all of the shorthands and just set the longhand instead
			changedProperties = {
				...changedProperties,
				...nulledSides,
				[baseProperty]: shortHandPropertiesValues[0]
			}

		} else {
			
			// When setting unique shorthand values, do not update the longhand as well. This trips ups inheritance handling for the CSS parser
			delete changedProperties[baseProperty];

		}

	}

	return changedProperties
}

export const handleMultiScrubber = ( baseProperty, propertyArr, selector, changedProp, changedProperties, existingMainAttr, existingSubAttrs ) => {

	let currentValues = { ...existingMainAttr, ...existingSubAttrs };
	let changedPropertiesCopy = { ...currentValues, ...changedProperties };

	let isMainScrubber = changedProp === baseProperty;

	let nulledSides = {
		[`${propertyArr[0]}`] : null,
		[`${propertyArr[1]}`] : null,
		[`${propertyArr[2]}`] : null,
		[`${propertyArr[3]}`] : null
	};

	// Object sync order depends on which scrubber value is being manipulated
	// If we are changing a sub scrubber, prioratize the changed properties.
	// If we are changing the main scrubber, prioratize the sub properties.
	changedPropertiesCopy = isMainScrubber ? { ...changedPropertiesCopy, ...nulledSides } : { ...nulledSides, ...changedPropertiesCopy }

	// Sync main scrubber with sub scrubbers if main scrubber value is different from sub scrubber value and all sub scrubbers are equal.
	if(    !isMainScrubber
		&& changedProperties[`${propertyArr[0]}`] === changedProperties[`${propertyArr[1]}`]
		&& changedProperties[`${propertyArr[0]}`] === changedProperties[`${propertyArr[2]}`]
		&& changedProperties[`${propertyArr[0]}`] === changedProperties[`${propertyArr[3]}`]
		&& ( 
			   changedProperties[`${propertyArr[0]}`] !== changedProperties[baseProperty]
			|| changedProperties[`${propertyArr[1]}`] !== changedProperties[baseProperty]
			|| changedProperties[`${propertyArr[2]}`] !== changedProperties[baseProperty]
			|| changedProperties[`${propertyArr[3]}`] !== changedProperties[baseProperty]
		  )
	){
		changedPropertiesCopy[baseProperty] = changedPropertiesCopy[`${propertyArr[0]}`];
	}

	if(!isMainScrubber) {

		const shorthandProperties = _.pick(changedPropertiesCopy, _.keys(nulledSides));
		const shortHandPropertiesValues = _.values(shorthandProperties);

		// if all shorthands are the exact same value, just set longhand and delete all shorthands
		if(shortHandPropertiesValues.length === 4 && shortHandPropertiesValues.every((val) => val === shortHandPropertiesValues[0])) {

			// when setting the exact same shorthands, kill all of the shorthands and just set the longhand instead
			changedPropertiesCopy = {
				...changedPropertiesCopy,
				...nulledSides,
				[baseProperty]: shortHandPropertiesValues[0]
			}

		} else {
			
			// When setting unique shorthand values, do not update the longhand as well. This trips ups inheritance handling for the CSS parser
			delete changedPropertiesCopy[baseProperty];

		}

	}

	return changedPropertiesCopy

}

export const isPropWatcherSetLocally = propWatcher => {

	if (propWatcher.value === null) {
		return false;
	}

	let parent = propWatcher.propWatcherUsedForInheritance;

	while(parent) {

		// the parent is using a different parser, meaning it's coming
		// from another stylesheet
		if(parent.parser !== propWatcher.parser) {
			return false;
		}

		// look up
		parent = parent.propWatcherUsedForInheritance;

	}

	return true;

}

// Helper function for loading commerce products in admin
// Here, rather than common because importing WS provider into common may not be possible.
export const adminCommerceProductFetchHelper = (productFetchRequired, fetchCommerceProducts, setState, updateAdminState ) => {

	let commerceOpen = false;
	// If this.props.adminState.productFetchRequired is false,
	//  we need to check the websocket to see if someone has commerce open.
	if( !productFetchRequired ){
		wsProvider.awareness.getStates().forEach(({hasCommerceOpen}) => {

			if(!hasCommerceOpen) {
				return;
			}

			if(
				// this state is using commerce
				hasCommerceOpen === true
				// and is not our own state
				&& hasCommerceOpen !== wsProvider.awareness.getLocalState()?.hasCommerceOpen
			) {
				commerceOpen = true;
			}

		});
	}
	// If we need to fetch products, or someone has commerce open, fetch products.
	if( productFetchRequired || commerceOpen ) {
		fetchCommerceProducts({
			limit: 999
		}).then((res)=>{
			// Set state to true to indicate that we have fetched products for the app.
			setState( true );
			// If we have fetched products, and no one has commerce open, we can set productFetchRequired to false.
			if( !commerceOpen ){
				updateAdminState({ productFetchRequired: false })
			}
		})
	}
	// If we don't need to fetch products, and no one has commerce open, set state to true.
	if( !productFetchRequired && !commerceOpen ) {
		setState( true );
	}

}

export const PropigateCustomFontFetch = () => {
	setTimeout(()=>{
		ydoc.transact(() => {

			const { CRDTItem: sharedAdminState } = getCRDTItem({
				reducer: 'adminState',
				item: 'crdt'
			});

			sharedAdminState.set('fontCollectionUpdatedAt', Date.now() )
			
		}, YTransactionTypes.NotUndoable);
	}, 100)
}

export const shouldPropigateCustomFontFetch = () => {
	const ignoreFamilies = [
		'Diatype Variable', 
		'Diatype Semi-Mono Variable',
		'Diatype Mono Variable',
		'Cargo Monument Grotesk Mono'
	];
	const documentFontSet = FRONTEND_DATA?.contentWindow?.document?.fonts;
	const documentFontFaces = [];
	if( documentFontSet ){
		documentFontSet.forEach((fontFace) => {
			if ( ignoreFamilies.includes(fontFace.family) ) {
				return;
			}
			documentFontFaces.push({
				family: fontFace.family,
				style: fontFace.style,
				weight: fontFace.weight,
			});
		});
	}
	const storeFontSet = store.getState().fontCollection.collection;
	const storeFontFaces = [];
	const standardFamilies = [];
	if( storeFontSet ){
		storeFontSet.forEach((fontFace) => {
			if ( ignoreFamilies.includes(fontFace.family) ) {
				return;
			}
			for (const variant of fontFace.variants) {
				if (fontFace.provider !== 'custom' && !standardFamilies.includes(variant.family)) {
					standardFamilies.push(variant.family);
				}
				storeFontFaces.push({
					family: variant.family,
					cssName: variant.cssName || variant.family,
					style: variant.style,
					weight: variant.weight,
					provider: fontFace.provider,
				});
			}
		});
	}
	const documentFontsThatAreNotInStore = documentFontFaces.filter((fontFace) => {
		if (standardFamilies.includes(fontFace.family)) {
			return false;
		}
		return !storeFontFaces.some((storeFontFace) => {
			if (storeFontFace.provider !== 'custom') {
				return false;
			}
			const hasFamilyMatch = storeFontFace.family === fontFace.family || storeFontFace.cssName === fontFace.family ? true : false;
			const hasStyleMatch = storeFontFace.style.toLowerCase() === fontFace.style.toLowerCase() ? true : false;
			const hasWeightMatch = Number(storeFontFace.weight) === Number(fontFace.weight) ? true : false;
			const isVariable = fontFace.family.toLowerCase().includes('variable') || !fontFace.weight;
			if (isVariable) {
				return hasFamilyMatch && hasStyleMatch;
			} else {
				return hasFamilyMatch && hasStyleMatch && hasWeightMatch;
			}
		})
	});
	const storeFontsThatAreNotInDocument = storeFontFaces.filter((fontFace) => {
		if (fontFace.provider !== 'custom') {
			return false;
		}
		return !documentFontFaces.some((documentFontFace) => {
			const hasFamilyMatch = documentFontFace.family === fontFace.family || documentFontFace.family === fontFace.cssName ? true : false;
			const hasStyleMatch = documentFontFace.style.toLowerCase() === fontFace.style.toLowerCase() ? true : false;
			const hasWeightMatch = Number(documentFontFace.weight) === Number(fontFace.weight) ? true : false;
		const isVariable = fontFace.family.toLowerCase().includes('variable') || fontFace.cssName.toLowerCase().includes('variable') || !fontFace.weight;
			if (isVariable) {
				return hasFamilyMatch && hasStyleMatch;
			} else {
				return hasFamilyMatch && hasStyleMatch && hasWeightMatch;
			}
		})
	});
	if (documentFontsThatAreNotInStore.length > 0) {
	}
	if (storeFontsThatAreNotInDocument.length > 0) {
	}
	return documentFontsThatAreNotInStore.length > 0 || storeFontsThatAreNotInDocument.length > 0 ? true : false;
}

const getCustomFontFamilyName = ({postScriptName, preferredFamily, fontFamily, uniqueID, cssName, isVariable, fileName}) => {
	let family = null;
	if (family === null && postScriptName && postScriptName !== 'false') {
		let postScriptFamily = postScriptName.split('-')[0]?.replace(/([A-Z])/g, ' $1').replace(/-/g, ' ');
		// Replace "X " with "X" for postScriptFamily
		family = postScriptFamily.replace(/X /g, 'X');
	}
	if (family === null && preferredFamily && preferredFamily !== 'false') {
		family = preferredFamily;
	}
	if (family === null && fontFamily && fontFamily !== 'false') {
		family = fontFamily;
	}
	if ( family === null && cssName && cssName !== 'false' && cssName !== 'ProcessingName' ) {
		family = cssName;
	}
	if ( family === null && uniqueID && uniqueID !== 'false' ) {
		// Get all characters after the last ; in uniqueID, before any hypen
		family = uniqueID.split(';').pop();
		family = family.split('-')[0];
	}
	if (family === null && fileName && fileName !== 'false') {
		family = fileName;
	}
	// Trim whitespace from name
	family = family.replace(/\s+/g, ' ').trim();
	// Replace any double spaces with single spaces
	family = family.replace(/\s\s+/g, ' ');
	// Remove special characters from family name
	family = family.replace(/[^a-zA-Z0-9 ]/g, '');
	// Remove diacritics from family name
	family = removeDiacritics(family);

	if (family === null && preferredFamily && preferredFamily !== 'false') {
		family = preferredFamily;
	}

	if (family === null || family === 'false' || family === '') {
		if (fileName && fileName !== 'false') {
			family = fileName;
		} else {
			family = 'Custom Font';
		}
	}

	if (isVariable && !family.toLowerCase().includes('variable')) {
		family = `${family} Variable`;
	}
	return family;
}

const getCustomFontName = ({postScriptName, family, uniqueID, subFamily, fullName, weight, style, isVariable, fileName}) => {

	//if (family.toLowerCase().includes("Mikrobe")) {
	//}
	let name = `${family} ${weight} ${style}`;

	let postScriptBasedName = '';
	if (postScriptName && postScriptName !== 'false') {
		let postScriptFamily = postScriptName.split('-')[0]?.replace(/([A-Z])/g, ' $1').replace(/-/g, ' ');
		postScriptFamily = postScriptFamily?.replaceAll(/X /g, 'X');
		// Replace "X " with "X" for postScriptFamily
		let postScriptStyle = postScriptName.endsWith('Italic') ? 'Italic' : postScriptName.endsWith('Oblique') ? 'Oblique' : '';
		let postScriptWeight = postScriptName.split('-')[1]?.replace(postScriptStyle, '')?.replace(/([A-Z])/g, ' $1');
		postScriptWeight = postScriptWeight?.replaceAll(/X /g, 'X');
		if (!postScriptWeight) {
			postScriptStyle = 'Regular';
		}
		// Handle mis named fonts
		if (postScriptStyle !== 'Italic' && style === 'italic') {
			postScriptStyle = 'Italic';
		}
		if (postScriptStyle !== 'Oblique' && style === 'oblique') {
			postScriptStyle = 'Oblique';
		}
		if (postScriptWeight === 'Regular' && weight !== '400') {
			switch(weight) {
				case '100':
					postScriptWeight = 'Thin';
					break;
				case '200':
					postScriptWeight = 'ExtraLight';
					break;
				case '300':
					postScriptWeight = 'Light';
					break;
				case '500':
					postScriptWeight = 'Medium';
					break;
				case '600':
					postScriptWeight = 'SemiBold';
					break;
				case '700':
					postScriptWeight = 'Bold';
					break;
				case '800':
					postScriptWeight = 'ExtraBold';
					break;
				case '900':
					postScriptWeight = 'Black';
					break;
				default:
					postScriptWeight = 'Regular';
			}
		}
		postScriptBasedName = `${postScriptFamily} ${postScriptWeight} ${postScriptStyle}`;
	}

	let familyBasedName = '';
	if (family && family !== 'false') {
		name = family;
		if (subFamily && subFamily !== 'false') {
			if (subFamily === 'Regular' && weight !== '400') {
				switch(weight) {
					case '100':
						subFamily = 'Thin';
						break;
					case '200':
						subFamily = 'ExtraLight';
						break;
					case '300':
						subFamily = 'Light';
						break;
					case '500':
						subFamily = 'Medium';
						break;
					case '600':
						subFamily = 'SemiBold';
						break;
					case '700':
						subFamily = 'Bold';
						break;
					case '800':
						subFamily = 'ExtraBold';
						break;
					case '900':
						subFamily = 'Black';
						break;
					default:
						subFamily = 'Regular';
				}
			}
			familyBasedName = `${family} ${subFamily}`;
		}
	}

	if (postScriptBasedName && postScriptBasedName !== 'false') {
		name = postScriptBasedName;
	}

	if (familyBasedName && familyBasedName !== 'false' && familyBasedName !== family) {
		name = familyBasedName;
	}

	// Above all else, trust the fullName
	if (
		fullName && 
		fullName !== 'false' && 
		fullName !== family &&
		isVariable === fullName.toLowerCase().includes('variable') &&
		fullName !== postScriptName
	) {
		name = fullName;
	}

	if (name.startsWith('/') || name.startsWith('./') || name.startsWith('.')) {
		name = familyBasedName;
	} 

	if (name === null || name === 'false' || name === '' || !name.match(/[a-zA-Z0-9]/)) {
		if (fileName && fileName !== 'false') {
			name = fileName;
		} else {
			name = 'Custom Font';
		}
	}

	// If the name still doesn't have variable in it, add it
	if (isVariable && !name.toLowerCase().includes('variable')) {
		name = `${name} Variable`;
	}

	if (style === 'italic' && !name.toLowerCase().includes('italic')) {
		name = `${name} Italic`;
	}

	if (postScriptName && postScriptName.toLowerCase().includes('bold') && !name.toLowerCase().includes('bold')) {
		name = `${name} Bold`;
	}

	if ( name.startsWith('false') ) {
		// Get all characters after the last ; in uniqueID
		name = uniqueID.split(';').pop();
	}

	// Trim whitespace from name
	name = name.replace(/\s+/g, ' ').trim();
	// Replace any double spaces with single spaces
	name = name.replace(/\s\s+/g, ' ');

	return name;
}

let decompressBindingLoadPromise = null;
export const getCustomFonts = async (targetWindow=FRONTEND_DATA.contentWindow) => {

	// const existingCustomFonts = store.getState()?.fontCollection?.collection?.length ? store.getState().fontCollection.collection.filter((font) => {
	// 	return font.provider === 'custom';
	// }) : [];

	const targetDocument = targetWindow.document;

	const loadCustomFonts = async () => {

		const customHTML = store.getState()?.site.custom_html || '';
		let customCSS = store.getState()?.css.stylesheet || '';

		const customFonts = [];
		// if we have custom fonts, look for corresponding embeds inside the custom html
		const frag = document.createRange().createContextualFragment(customHTML);


		let customFontsInCustomHTML = Array.from(frag.children).filter(el=>{
			return el.hasAttribute('embedded-font');
		}).map(el=>{
			return {

				// returns comma-delineated list of fonts embedded by that specific embed code like
				// 'Roboto, Open Sans'
				// or 'Some Other Font'
				embeddedFontReference: el.getAttribute('embedded-font'),

				// returns <link embedded-font="Roboto" src="etc" />  , <script src="etc.js" embedded-font="Specially Subsetted Eastern Asian Font"></script>
				embedCode: el.outerHTML,
			}
		})

		const getIsolatedStyleSheet = async (css, depth=0, fetchedURLs=[]) => {

			const doc = targetDocument.implementation.createDocument('http://www.w3.org/1999/xhtml', 'body', null);
			const style = targetDocument.createElement('style');
			style.innerHTML = css;
			doc.children[0].appendChild(style);

			for ( const rule of style.sheet.cssRules ) {
				if (rule.type === 3 || rule.type === CSSRule.IMPORT_RULE && depth < 2 && fetchedURLs.indexOf(rule.href) == -1) {

					depth = depth+1;
					fetchedURLs.push(rule.href);

					let fetchedStyle = null;	

					try {
						const response = await fetch(rule.href);
						let text = await response.text();
						// Reformat any relative urls in the CSS (text) to absolute urls using rule.href
						const replacements = {
							'': '',
							// Remove the last 2 segments of the url
							'../': rule.href.replace(/\/[^\/]*\/[^\/]*$/, '/'),
							// Remove everything after the last slash
							'./': rule.href.replace(/\/[^\/]*$/, '/'),
							// Get the base url (everything before the first slash including the protocol)
							'/': new URL(rule.href).origin + '/',
						}
						if (text.includes('url(./') || text.includes('url(../') || text.includes('url(/')) {
							text = text.replace(/url\((.*?)\)/g, (match, p1) => {
								return `url(${replacements[p1.match(/^(\.\/|\.\.\/|\/)/)?.[1]]}${p1.replace(/^(\.\/|\.\.\/|\/)/, '')})`;
							});
						}
						fetchedStyle = await getIsolatedStyleSheet(text, depth,fetchedURLs);
					} catch (e) {
						const focusOrder = store.getState().uiWindows.focusOrder;
						if (focusOrder.includes('custom-font-wizard')) {
							document.dispatchEvent(new CustomEvent('open-remote-alert', {
								detail: {
									message: 'Something went wrong',
								}
							}));
						}
						continue;
					}
					style.innerHTML+=fetchedStyle.textContent
				}

			}

			return style;
	
		}

		function typedArrayToBuffer(array) {
			return array.buffer.slice(array.byteOffset, array.byteLength + array.byteOffset)
		}

		let css = '';
		const cssFontFaces = {
			html: {
				link: {},
				import: {},
				style: []
			},
			css: {
				import: {},
				style: [],
			}
		};


		for ( const sheet of targetDocument.styleSheets ) {

			const url = sheet.href;

			// ignore cargo-generated stylesheets on the frontend
			if( sheet.ownerNode && FRONTEND_DATA.contentWindow.document.head.contains(sheet.ownerNode)){
				continue;
			}

			// Only look at stylesheets that are not from the same origin as the current page
			if (!url || url.startsWith('../') || url.startsWith('./') || url.startsWith('/')) {
				continue;
			}

			let text = null;
			try {
				const response = await fetch(url);
				text = await response.text();
				// Reformat any relative urls in the CSS (text) to absolute urls using url
				const replacements = {
					'': '',
					// Remove the last 2 segments of the url	
					'../': url.replace(/\/[^\/]*\/[^\/]*$/, '/'),
					// Remove everything after the last slash
					'./': url.replace(/\/[^\/]*$/, '/'),
					// Get the base url (everything before the first slash including the protocol)
					'/': new URL(url).origin + '/',
				}
				if (text.includes('url(./') || text.includes('url(../') || text.includes('url(/')) {
					text = text.replace(/url\((.*?)\)/g, (match, p1) => {
						return `url(${replacements[p1.match(/^(\.\/|\.\.\/|\/)/)?.[1]]}${p1.replace(/^(\.\/|\.\.\/|\/)/, '')})`;
					});
				}
			} catch (e) {
				const focusOrder = store.getState().uiWindows.focusOrder;
				if (focusOrder.includes('custom-font-wizard')) {
					document.dispatchEvent(new CustomEvent('open-remote-alert', {
						detail: {
							message: 'Something went wrong',
						}
					}));
				}
				continue;
			}

			if (text.includes('@font-face')) {
				cssFontFaces['html']['link'][url] = text.match(/@font-face\s*{[^}]*}/g);
				css += text;
			}
		}

		// ignore editor overlay and page-bound embeds
		for (const style of Array.from(targetDocument.querySelectorAll('style'))) {

			if (style.textContent === customCSS) {
				continue;
			}

			if( style.closest('#editor-overlay, bodycopy') ){
				continue;
			}

			let styleText = style.textContent;

			// If the style contains any @font-face rules, add them to the cssFontFaces object
			if (styleText.includes('@font-face')) {
				const rules = styleText.match(/@font-face\s*{[^}]*}/g);
				cssFontFaces['html']['style'] = cssFontFaces['html']['style'].concat(rules);
			}

			// Check if the style contains any imported css
			const importedCSS = styleText.match(/@import url\((.*?)\)/g);
			if (importedCSS) {
				for (const url of importedCSS) {
					let importURL = url.replace(/@import url\((.*?)\)/g, `$1`);
					// If importURL has single or double quotes around it, remove them
					if (
						importURL[0] === "'" && importURL[importURL.length - 1] === "'" ||
						importURL[0] === '"' && importURL[importURL.length - 1] === '"'
					) {
						importURL = importURL.slice(1, -1);
					}
					let text = null;
					try {
						const response = await fetch(importURL);
						text = await response.text();
						// Reformat any relative urls in the imported css (text) to absolute urls using the importURL
						const replacements = {
							'': '',
							// Remove the last 2 segments of the url
							'../': importURL.replace(/\/[^\/]*\/[^\/]*$/, '/'),
							// Remove everything after the last slash
							'./': importURL.replace(/\/[^\/]*$/, '/'),
							// Get the base url (everything before the first slash including the protocol)
							'/': new URL(importURL).origin + '/',
						}
						if (text.includes('url(./') || text.includes('url(../') || text.includes('url(/')) {
							text = text.replace(/url\((.*?)\)/g, (match, p1) => {
								return `url(${replacements[p1.match(/^(\.\/|\.\.\/|\/)/)?.[1]]}${p1.replace(/^(\.\/|\.\.\/|\/)/, '')})`;
							});
						}
					} catch (e) {
						const focusOrder = store.getState().uiWindows.focusOrder;
						if (focusOrder.includes('custom-font-wizard')) {
							document.dispatchEvent(new CustomEvent('open-remote-alert', {
								detail: {
									message: 'Something went wrong',
								}
							}));
						}
						continue;
					}
					// If the imported css includes a font-face rule, we need to replace the url with the text
					if (text.includes('@font-face')) {
						cssFontFaces['html']['import'][importURL] = text.match(/@font-face\s*{[^}]*}/g);
						styleText = styleText.replace(url, text);
					} else {
						// Remove the import rule if it doesn't include a font-face rule
						styleText = styleText.replace(url, '');
					}
				}
			}

			css+= styleText;
		};

		const importedCSS = customCSS.match(/@import url\((.*?)\)/g);

		cssFontFaces['css']['style'] = customCSS.match(/@font-face\s*{[^}]*}/g) ?? [];

		if (importedCSS) {
			for (const url of importedCSS) {
				let importURL = url.replace(/@import url\((.*?)\)/g, `$1`);
				// If importURL has single or double quotes around it, remove them
				if (
					importURL[0] === "'" && importURL[importURL.length - 1] === "'" ||
					importURL[0] === '"' && importURL[importURL.length - 1] === '"'
				) {
					importURL = importURL.slice(1, -1);
				}
				let text = null;
				try {
					const response = await fetch(importURL);
					text = await response.text();
					// Reformat any relative urls in the imported css (text) to absolute urls using the importURL
					const replacements = {
						'': '',
						// Remove the last 2 segments of the url
						'../': importURL.replace(/\/[^\/]*\/[^\/]*$/, '/'),
						// Remove everything after the last slash
						'./': importURL.replace(/\/[^\/]*$/, '/'),
						// Get the base url (everything before the first slash including the protocol)
						'/': new URL(importURL).origin + '/',
					}
					if (text.includes('url(./') || text.includes('url(../') || text.includes('url(/')) {
						text = text.replace(/url\((.*?)\)/g, (match, p1) => {
							return `url(${replacements[p1.match(/^(\.\/|\.\.\/|\/)/)?.[1]]}${p1.replace(/^(\.\/|\.\.\/|\/)/, '')})`;
						});
					}
				} catch (e) {
					const focusOrder = store.getState().uiWindows.focusOrder;
					if (focusOrder.includes('custom-font-wizard')) {
						document.dispatchEvent(new CustomEvent('open-remote-alert', {
							detail: {
								message: 'Something went wrong',
							}
						}));
					}
					continue;
				}
				// If the imported css includes a font-face rule, we need to replace the url with the text
				if (text.includes('@font-face')) {
					cssFontFaces['css']['import'][importURL] = text.match(/@font-face\s*{[^}]*}/g);
					customCSS = customCSS.replace(`${url};`, text);
				} else {
					// Remove the import rule if it doesn't include a font-face rule
					customCSS = customCSS.replace(`${url};`, '');
				}
			}
		}

		css += customCSS;

		const isolatedStyleSheet = await getIsolatedStyleSheet(css);

		const loadScript = (src) => new Promise((onload) => document.documentElement.append(
			Object.assign(document.createElement('script'), {src, onload})
		));

		let out, parsed, name, family, embed;
		  
		// Loop through all the rules in the stylesheet
		for ( const rule of isolatedStyleSheet.sheet.cssRules ) {

			// If the rule is a font-face rule
			if (rule.type === 5 || rule.type === CSSRule.FONT_FACE_RULE) {

				if(!rule.style.fontFamily || !rule.style.src) {
					continue;
				}

				const cssName = rule.style.fontFamily.replaceAll('"', '');
				const src = parseFontFaceSrc(rule.style.src);
				let url = src.find((obj) => obj.url)?.url;					
				if (!url || url.startsWith('../') || url.startsWith('./') || url.startsWith('/')) {
					console.error('no absolute url', rule, src);
					continue;
				}
				const decodeEntities = (encodedString) => {
					var textArea = document.createElement('textarea');
					textArea.innerHTML = encodedString;
					return textArea.value;
				}
				const getEmbedBySrc = (src) => {
					let embedReference = null;
					const arrsToSearch = [
						cssFontFaces.html.style,
						cssFontFaces.css.style,
					];
					const locations = [
						'html.style',
						'css.style',
					];
					for (const importUrl of Object.keys(cssFontFaces.html.link)) {
						arrsToSearch.push(cssFontFaces.html.link[importUrl]);
						locations.push(`html.link['${importUrl}']`);
					}
					for (const importUrl of Object.keys(cssFontFaces.html.import)) {
						arrsToSearch.push(cssFontFaces.html.import[importUrl]);
						locations.push(`html.import['${importUrl}']`);
					}
					for (const importUrl of Object.keys(cssFontFaces.css.import)) {
						arrsToSearch.push(cssFontFaces.css.import[importUrl]);
						locations.push(`css.import['${importUrl}']`);
					}
					for (const [index, arr] of arrsToSearch.entries()) {
						// Check if the src exists within any of the strings in the array
						const match = arr.find((str) => {
							return str.includes(src) || str.includes(decodeEntities(src));
						});
						if (match) {
							// Parse the font-family from the match
							let cssFamily = match.match(/font-family:\s*([^;]*)/)[1];
							// Trim any single or double quotes from the font-family
							cssFamily = cssFamily.replace(/['"]/g, '');
							embedReference = {
								location: locations[index],
								rule: match,
							}
							break;
						}
					}
					return embedReference;
				}
				embed = getEmbedBySrc(url);

				const format = src.find((obj) => obj.url && obj.url === url).format;
				const cacheKey = 'font-parse-cache-' + url;
				
				// If the format is woff2, we need to decompress it before parsing
				if (format.includes('woff2')) {

					// decompress before parsing
					try {
						
						if (Object.keys([fontLoadResults]).length > 10000) {
							throw new Error('Too many external fonts loaded');
							continue;
						}

						if (fontLoadResults[url] && fontLoadResults[url] === 'failed') {
							throw new Error('Attempted to load font that failed previously.');
							continue;
						}

						let cachedResult = null;

						try {
							if(localStorage.getItem(cacheKey)) {
								cachedResult = JSON.parse(localStorage.getItem(cacheKey));
							}
						} catch(e) {
							// delete invalid cache key
							try {
								localStorage.removeItem(cacheKey);
							} catch(e) {console.error(e)}
						}

						if (cachedResult) {
							
							parsed = cachedResult;
							fontLoadResults[url] = 'success';

						} else {

							if (!decompressBindingLoadPromise) {

								decompressBindingLoadPromise = new Promise( async (resolve) => {
									
									const path = PUBLIC_URL + '/js/wawoff@2.0.1-decompress_binding.js';
				
									// Check if the decompress binding is already loaded
									if (window.Module) {
										resolve();
										return;
									}
				
									const init = new Promise((done) => window.Module = { onRuntimeInitialized: done});
									await loadScript(path).then(() => init);
				
									resolve();
								})
				
							}

							await decompressBindingLoadPromise;

							const buffer = await fetch(url, {
								method: "GET",
								cache: "no-store",
								mode: "cors"
							}).then(res => res.arrayBuffer());

							out = window.Module.decompress(buffer);

							try {
								parsed = opentype.parse(typedArrayToBuffer(out));
							} catch (e) {
								console.error(`Error parsing ${format} custom font: ${url}`, e);
								fontLoadResults[url] = 'failed';
								continue;
							}

							try {
								// Cache results
								localStorage.setItem(cacheKey, JSON.stringify({
									names: parsed.names,
									tables: {
										fvar: parsed.tables.fvar,
									},
								}));
							} catch(e) {console.error(e)};

							fontLoadResults[url] = 'success';

						}

						family = getCustomFontFamilyName({
							postScriptName: parsed?.names?.postScriptName?.en,
							preferredFamily: parsed?.names?.preferredFamily?.en,
							fontFamily: parsed?.names?.fontFamily?.en,
							uniqueID: parsed?.names?.uniqueID?.en,
							cssName: cssName,
							isVariable: parsed?.tables?.fvar ? true : false,
							fileName: url.split('/').pop().split('.')[0],
						});

						if (family === 'false') {
							family = parsed.names.uniqueID?.en.split(';').pop();
						}
						
						name = getCustomFontName({
							postScriptName: parsed?.names?.postScriptName?.en,
							family,
							subFamily: parsed?.names?.preferredSubfamily?.en ?? parsed.names.fontSubfamily?.en,
							fullName: parsed?.names?.fullName?.en,
							uniqueID: parsed?.names?.uniqueID?.en,
							weight: rule.style.fontWeight,
							style: rule.style.fontStyle,
							isVariable: parsed?.tables?.fvar ? true : false,
							fileName: url.split('/').pop().split('.')[0],
						});

					} catch (e) {
						fontLoadResults[url] = 'failed';
						console.error('e', rule.style.fontFamily, e);
						
						// delete invalid cache key
						try {
							localStorage.removeItem(cacheKey);
						} catch(e) {console.error(e)}

						continue;
					}
				} else {

					try {
						if (Object.keys([fontLoadResults]).length > 10000) {
							throw new Error('Too many external fonts loaded');
						}
						if (fontLoadResults[url] && fontLoadResults[url] === 'failed') {
							throw new Error('Attempted to load font that failed previously.');
							continue;
						}

						let cachedResult = null;

						try {
							if(localStorage.getItem(cacheKey)) {
								cachedResult = JSON.parse(localStorage.getItem(cacheKey));
							}
						} catch(e) {
							// delete invalid cache key
							try {
								localStorage.removeItem(cacheKey);
							} catch(e) {console.error(e)}
						}

						if (cachedResult) {
							parsed = cachedResult;
							fontLoadResults[url] = 'success';
						} else {
							const buffer = await fetch(url, {
								method: "GET",
								cache: "no-store",
								mode: "cors"
							}).then(res => res.arrayBuffer());
							fontLoadResults[url] = 'success';
							parsed = opentype.parse(buffer);
							// Set cache
							const cacheId = 'font-parse-cache-' + url;
							const cacheStr = JSON.stringify({
								names: parsed.names,
								tables: {
									fvar: parsed.tables.fvar,
								},
							})

							try {
								localStorage.setItem(cacheId, cacheStr);
							} catch(e) {console.error(e)};

						}

						family = getCustomFontFamilyName({
							postScriptName: parsed?.names?.postScriptName?.en,
							preferredFamily: parsed?.names?.preferredFamily?.en,
							fontFamily: parsed?.names?.fontFamily?.en,
							uniqueID: parsed?.names?.uniqueID?.en,
							cssName: cssName,
							isVariable: parsed?.tables?.fvar ? true : false,
							fileName: url.split('/').pop().split('.')[0],
						});

						name = getCustomFontName({
							postScriptName: parsed?.names?.postScriptName?.en,
							family: family,
							subFamily: parsed?.names?.preferredSubfamily?.en ?? parsed.names.fontSubfamily?.en,
							fullName: parsed.names.fullName?.en,
							uniqueID: parsed.names.uniqueID?.en,
							weight: rule.style.fontWeight,	
							style: rule.style.fontStyle,
							isVariable: parsed.tables.fvar ? true : false,
							fileName: url.split('/').pop().split('.')[0],
						});

					} catch (e) {
						fontLoadResults[url] = 'failed';
						console.error('e', e);

						// delete invalid cache key
						try {
							localStorage.removeItem(cacheKey);
						} catch(e) {console.error(e)};

						continue;
					}

				}

				let CSSStyle = null;

				const genericStyles = {
					'italic': 'italic',
					'slanted': 'italic',
					'oblique': 'oblique',
				}

				// Try to find the style in the style
				if( rule.style.fontStyle && CSSStyle === null ){
					CSSStyle = rule.style.fontStyle;
				}

				// Try to find the style in the name
				for (const [genericStyle, style] of Object.entries(genericStyles)) {
					if (name.toLowerCase().includes(genericStyle) && CSSStyle === null) {
						CSSStyle = style;
						break;
					}
				}

				// Try to find the style in the parsed font
				if ( parsed.tables.cff?.topDict?.italicAngle && CSSStyle === null ){
					let dictStyle = parsed.tables.cff.topDict.italicAngle;
					if( dictStyle < 0 ){
						CSSStyle = 'italic'
					} else if ( dictStyle > 0 ){
						CSSStyle = 'oblique'
					}
				}

				if (CSSStyle === null) {
					CSSStyle = 'normal';
				}

				let CSSWeight = null;

				const genericWeights = {
					'ultra light': 100,
					'ultralight': 100,
					'extralight': 100,
					'extra light': 100,
					'light': 200,
					'thin': 300,
					'regular': 400,
					'normal': 400,
					'medium': 500,
					'book': 500,
					'semi bold': 600,
					'semibold': 600,
					'demi bold': 600,
					'demibold': 600,
					'bold': 700,
					'extrabold': 800,
					'extra bold': 800,
					'black': 900,
					'heavy': 900,
					'ultra': 900,
					'ultrablack': 900,
					'ultra black': 900,
					'ultraheavy': 900,
					'ultra heavy': 900,
					'extra black': 900,
					'extrablack': 900,
				}

				// Try to find the weight in the style
				if( rule.style.fontWeight && CSSWeight === null ){
					CSSWeight = rule.style.fontWeight;
				}

				// Try to find the weight in the name
				for (const [genericWeight, weight] of Object.entries(genericWeights)) {
					if (name.toLowerCase().includes(genericWeight) && CSSWeight === null) {
						CSSWeight = weight;
						break;
					}
				}

				// Try to find the weight in the parsed font
				if ( parsed.tables.cff?.topDict?.weight && CSSWeight === null ){
					let dictWeight = parsed.tables.cff.topDict.weight.toLowerCase().trim();
					if( dictWeight=== 'normal' || dictWeight ==='regular'){
						CSSWeight = 400
					} else if ( dictWeight === 'bold' ){
						CSSWeight = 700
					} else if ( dictWeight === 'extrabold' || dictWeight ==='extra bold'){
						CSSWeight = 800
					} else if ( dictWeight === 'black'){
						CSSWeight = 900
					}
				}

				if (CSSWeight === null) {
					CSSWeight = 400;
				}

				// Check if a font with the same cssName, weight and style already exists
				const existingFont = customFonts.find((font) => {
					return font.cssName === cssName && font.weight === CSSWeight && font.style === CSSStyle;
				});

				if (existingFont) {
					// Remove the existing font from the array
					customFonts.splice(customFonts.indexOf(existingFont), 1);
				}

				customFonts.push({
					name: name,			
					embed: embed,
					cssName: cssName,
					family: family,
					// family: parsed.names.fontFamily.en,
					weight: CSSWeight,
					style: CSSStyle,
					parsed: parsed
				});				
			}
		}

		const customFontsWithVariableFonts = [];

		// Loop through custom fonts to handle variable fonts
		for (const [index, font] of customFonts.entries()) {
			if (!font?.parsed?.tables?.fvar || font?.parsed?.tables?.fvar?.instances?.length === 0) {
				// If the font is not a variable font, skip
				customFontsWithVariableFonts.push(font);
				continue;
			}

			// Get shared values
			const cssName = font.cssName;
			const familyName = font.family;
			const fontName = font.name;
			const axes = font.parsed.tables.fvar.axes;

			// Create an array of all the variants, based on the fvar instances
			const variants = [];
			for (const instance of font.parsed.tables.fvar.instances) {

				const isDefault = Object.keys(instance.coordinates).every((key) => {
					return instance.coordinates[key] === axes.find((axis) => axis.tag === key).defaultValue;
				});

				let instanceStyle = instance.coordinates.ital ? 'italic' : 'normal';
				let instanceWeight = instance.coordinates.wght;

				if (instanceStyle === 'normal' && font.style === 'italic') {
					instanceStyle = 'italic';
				}

				let backupName = fontName !== familyName ? fontName : familyName;
				if (isDefault) {
					backupName = familyName;
				}

				const variant = {
					name: instance.name.en ? familyName + ' ' + instance.name.en : backupName,
					fvs: Object.keys(instance.coordinates).map((key) => {
						return `'${key}' ${instance.coordinates[key]}`;
					}).join(', '),
					weight: instanceWeight,
					style: instanceStyle,
					cssName: cssName,
					family: familyName,
					parsed: font.parsed,
					axes: axes,
					embed: font.embed,
					fvar: true,
				};
				variants.push(variant);
			}

			// Add the variants to the array
			customFontsWithVariableFonts.push(...variants);
		}			

		const customFontsWithoutDuplicates = [];

		// Loop through custom fonts to remove duplicates
		for ( const font of customFontsWithVariableFonts ) {
			const isVariable = font.fvar ? true : false;
			let isDuplicate = false;
			// If customFontsWithoutDuplicates already includes a font with the same name, skip 
			if (customFontsWithoutDuplicates.some((customFont) => {
				return customFont.name === font.name;
			})) {
				isDuplicate = true;
			}

			// If the font is a variable font and a duplicate, then replace the existing font with the variable font
			if (isVariable && isDuplicate) {
				customFontsWithoutDuplicates.forEach((customFont, index) => {
					if (customFont.name === font.name) {
						customFontsWithoutDuplicates[index] = font;
					}
				});
			} else if (!isDuplicate) {
				// If the font is not a duplicate, add it to the array
				customFontsWithoutDuplicates.push(font);
			}
		}

		return customFontsWithoutDuplicates;
	}

	const capitalize = (word) => {
        return word
            .toLowerCase()
            .replace(/\w/, firstLetter => firstLetter.toUpperCase());
    }

	const loadedCustomFonts = await loadCustomFonts();

	const parsedCustomFonts = loadedCustomFonts.reduce((prevVal, currVal, currIndex) => {

		// Create new family and add variant
		let parsed_family_name = currVal.family;

		// Remove any special characters
		parsed_family_name = removeDiacritics(parsed_family_name);
		// Remove double spaces
		parsed_family_name = parsed_family_name.replace(/\s\s+/g, ' ');

		// Determine if this font is part of a family
		if (prevVal.some((font) => font["parsed_family_name"] === parsed_family_name)) {

			// Add variant to exisitng family
			prevVal.forEach((font) => {
				if (font["parsed_family_name"] === parsed_family_name) {
					font.variants.push({
						"vid": 9000 + currIndex,
						"family_id": 900 + currIndex,
						"file_name": font.postScriptName,
						"name": currVal.name,
						"google_variant_name": null,
						"css_font_style": currVal.style,
						"css_font_weight": currVal.weight,
						"embed": currVal.embed,
						"weight": currVal.weight,
						"css_font_fvd": null, //"n2",
						"css_font_family": currVal.cssName,
						"css_font_fvs": currVal.fvs ? currVal.fvs : undefined,
						"staff_pick": false,
						"axes": currVal.axes ? currVal.axes.map((axis, index) => {
							return {
								"id": index,
								"title": axis.name.en,
								"min": axis.minValue,
								"max": axis.maxValue,
								"step": (axis.maxValue - axis.minValue) / 100,
								"css_fvs": axis.tag
							}
						}) : null,
					});
				}
			});
		} else {
			prevVal.push({
				"parsed_family_name": parsed_family_name,
				"embeddedFontReference": currVal.embeddedFontReference,	
				"embedCode": currVal.embedCode,
				"embed": currVal.embed,
				"id": 900 + currIndex,
				"family_name": parsed_family_name,
				"google_family_name": null,
				"websafe_name": currVal.cssName,
				"provider": "custom",
				"foundry": null,
				"serif": null,
				"css_font_fvs": currVal.fvs ? currVal.fvs : undefined,
				"axes": currVal.axes ? currVal.axes.map((axis, index) => {
					return {
						"id": index,
						"title": axis.name.en,
						"min": axis.minValue,
						"max": axis.maxValue,
						"step": (axis.maxValue - axis.minValue) / 100,
						"css_fvs": axis.tag
					}
				}) : null,
				"sort": 900 + currIndex,
				"is_active": true,
				"variable": currVal.fvar ? true : false,
				"open_source": false,
				"license": "/*\n * This CSS file has been generated and is served by Cargo Collective Inc\n * and is authorized to be used on Cargo Collective Inc only.\n *\n * This CSS resource incorporates links to font software which is\n * the valuable copyrighted property of Dinamo Typefaces. You may not\n * attempt to copy, install, redistribute, convert, modify or reverse\n * engineer this font software. Please contact Dinamo with any\n * questions: https://abcdinamo.com/\n */",
				"variants": [
					{
						"vid": 9000 + currIndex,
						"family_id": 900 + currIndex,
						"file_name": currVal.postScriptName,
						"name": currVal.name,
						"embed": currVal.embed,
						"google_variant_name": null,
						"css_font_style": currVal.style,
						"css_font_weight": currVal.weight,
						"css_font_fvd": null, //"n2",
						"css_font_family": currVal.cssName,
						"css_font_fvs": currVal.fvs ? currVal.fvs : undefined,
						"staff_pick": false,
						"axes": currVal.axes ? currVal.axes.map((axis, index) => {
							return {
								"id": index,
								"title": axis.name.en,
								"min": axis.minValue,
								"max": axis.maxValue,
								"step": (axis.maxValue - axis.minValue) / 100,
								"css_fvs": axis.tag
							}
						}) : null,
					},
				]
			});
		}
		return prevVal;
	}, []);

	parsedCustomFonts.forEach((customFont, index) => {

		if (customFont.variants.length === 1) {
			// customFont.variants[0].name = customFont.family_name;
		} else {
			// Sort variants by their weight
			customFont.variants.sort((a, b) => {
				return a.name < b.name ? -1 : 1;
			});
			customFont.variants.sort((a, b) => {
				return parseInt(a.css_font_weight) - parseInt(b.css_font_weight);
			});
		}
	})

	return parsedCustomFonts;	
}

export const enforceSuperFamilyClipping = ( clippingObject, changedAxis, changeValue, currentFVSmap, currentFontWeight ) => {

	if( !clippingObject ){ return null }

	let clippingAxisSpec = clippingObject?.[changedAxis];

	if( !clippingAxisSpec ){ return null }

		let fvsMap = [];

		let greaterThan = ( clippingAxisSpec.greaterThan || clippingAxisSpec.greaterThan === 0 ) && ( changeValue > clippingAxisSpec.greaterThan );
		let lessThan = ( clippingAxisSpec.lessThan || clippingAxisSpec.lessThan === 0 ) && ( changeValue < clippingAxisSpec.lessThan );

		if( greaterThan || lessThan ){
	
			_.each(['min', 'max'], (typeKey, i) => {
				// Key will be min or max.
				let clippingFvsKeys = Object.keys(clippingAxisSpec[typeKey]);
				_.each( clippingFvsKeys, ( fvsKey ) => {

					let currMapIndex = currentFVSmap.findIndex((string)=> string.includes(fvsKey) );

					if( currMapIndex === -1 && fvsKey !== 'wght' ){ return null }
					let currMapProperty = fvsKey !== 'wght' ? currentFVSmap[currMapIndex].trim().split("' ")[0].trim().replace(/\'/g, '') : 'wght';
					let currMapVal      = fvsKey !== 'wght' ? currentFVSmap[currMapIndex].trim().split("' ")[1] : currentFontWeight;

					if( !currMapVal && currMapVal !== 0 ){ return null }

					currMapVal = parseFloat(currMapVal);

					let limitValue = clippingAxisSpec[typeKey][fvsKey];
					let shouldLimitMin = typeKey === 'min' && currMapProperty === fvsKey && currMapVal < limitValue;
					let shouldLimitMax = typeKey === 'max' && currMapProperty === fvsKey && currMapVal > limitValue;

					if( shouldLimitMin || shouldLimitMax ){
						let fvsString = `'${fvsKey}' ${limitValue}`
						fvsMap.push( fvsString );
					}
	
				});

			});

			return fvsMap;
		} else {
			return null;
		}
}


export const getFontSpriteSrc = ()=> {

	// Set local storage, or change false to true to test font sprite without cache.
	const fontSpriteTesting = localStorage.getItem('test-font-sprite') ?? false;

	let src;

	if( !fontSpriteTesting ){
		src = CARGO_ENV !== 'production' ? 'https://static.cargo.site/assets/builds/font-sprite-c3-dev.png' : 'https://static.cargo.site/assets/builds/font-sprite-c3.png?v=' + BUILD_VERSION;
	} else {
		src = CARGO_ENV !== 'production' ? 'https://static.cargo.site/assets/builds/font-sprite-c3-dev.png?v='+ Date.now() : 'https://static.cargo.site/assets/builds/font-sprite-c3.png?v=' + BUILD_VERSION;
	}
	
	return src;
}

export const getParentSet = (pageId) => {

	if( !pageId ){
		return false; 
	}

	const state = window.store.getState();
	return selectors.getItemParentId(state, pageId)
}